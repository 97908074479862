.action-step-editor {
  .title-bar {
    display: flex;
    justify-content: space-between;
    .js-toggle {
      margin-left: auto;
      margin-right: 30px;
    }
    .raw-edit-toggle {
      font-size: 22px;
    }
  }
  .steps-reset {
    margin-left: 15px;
  }
  .diagram-viewer {
    min-height: calc(100vh - 300px);
    background-color: #f7f7f7;
  }
  .step-item-form {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    .ant-divider {
      margin: 16px 0;
    }
    .spacer {
      flex: 1;
    }
    .step-action-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
  #code-editor {
    min-height: calc(100vh - 300px);
    box-shadow: 0 0 2px 1px #999;
  }
}
