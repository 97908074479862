@import "node_modules/react-image-gallery/styles/scss/image-gallery.scss";

.car-data-table {
  .caption-row {
    display: flex;
    align-items: center;
    .reload-data {
      margin: 0 20px;
    }
  }
  .image-gallery {
    max-width: 600px;
  }
  .expanded-row {
    width: calc(100vw - 400px);
  }
}
