.global-header {
  display: flex;
  background: #fff !important;
  padding: 0 20px;
  justify-content: flex-end;
  align-items: center;
  .header-menu {
    margin-left: 20px;
  }
}
