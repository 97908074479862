.themes-listing {
  .themes-topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .themes-reload {
      font-size: 20px;
      margin-left: 10px;
    }
  }
  .action-wrapper {
    min-width: 170px;
    text-align: right;
    .anticon {
      font-size: 18px;
      margin: 0 3px;
      cursor: pointer;
    }
  }
}
