.main-layout {
  height: 100vh;
  overflow-y: hidden;
  .main-container {
    overflow-y: auto;
  }
  .sites-content {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 20px);
    margin: 10px;
    padding: 10px;
    background-color: #fff;
  }
}
