.sidebar {
  .logo {
    position: relative;
    height: 42px;
    margin: 11px;
    color: #fff;
    font-size: 28px;
    overflow: hidden;
    text-align: center;
    img {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
