.site-detail {
  flex: 1;
  min-height: 100%;
  .site-name {
    display: flex;
    align-items: center;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .site-url {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .actions-wrapper {
    display: flex;
    align-items: center;
    .anticon {
      margin: 0 10px;
      cursor: pointer;
      font-size: 22px;
    }
    .configure-wrapper {
      cursor: pointer;
      position: relative;
      margin-top: -30px;
      margin-right: -10px;
      margin-bottom: -37px;
      padding: 20px 5px 25px;
      border: 1px solid darkgrey;
      &.active {
        background: #555;
        color: #fff;
      }
    }
  }
}
