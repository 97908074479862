.dashboard {
  background: white;
  .summary-reload {
    font-size: 28px;
    text-align: right;
    margin-left: 30px;
  }
  .summary-wrapper {
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    align-items: flex-start;
    .summary-item {
      padding: 20px;
      margin: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
      box-shadow: 0 0 7px 0px #ccc;
      label {
        font-size: 28px;
      }
      span {
        font-size: 34px;
        padding: 10px;
        font-weight: bold;
      }
      &.hand-cursor {
        cursor: pointer;
      }
    }
  }
}
