.sites-listing {
  .sites-topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sites-reload {
      font-size: 20px;
      margin-left: 10px;
    }
  }
  .action-wrapper {
    min-width: 160px;
    text-align: right;
    .anticon {
      font-size: 18px;
      margin: 0 3px;
      cursor: pointer;
    }
  }
  .search-wrapper {
    flex: 1;
    display: flex;
    margin-left: 50px;
    .query-input {
      margin-right: 10px;
      width: 220px;
      margin-right: 10px;
    }
    .theme-selector {
      width: 160px;
      margin-right: 20px;
    }
  }
}
